import React from "react";
import Layout from "utils/Layout";
import Copyrights from "components/Copyrights";
import { pageContent } from "staticContent/copyrights";

const IndexPage = () => (
  <Layout subTitle={pageContent.pageTitle}>
    <Copyrights />
  </Layout>
);

export default IndexPage;
