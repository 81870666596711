import React from "react";

const CopyrightsNoteWithList = ({ content }) => {
  const list = content.iconList.map((icon, index) => (
    <li key={index} className="copyrights__list-item">
      <a href={icon.link} {...content.sharedLinkSettings}>
        {icon.name}
      </a>
    </li>
  ));

  return (
    <div>
      <p className="copyrights__note copyrights__note--list-paragraph">
        {content.note}
      </p>
      <ul className="copyrights__list">{list}</ul>
    </div>
  );
};

export default CopyrightsNoteWithList;
