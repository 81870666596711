import { designerPortfolio, copyrights } from "routes/externalPaths";

const pageContent = {
  pageTitle: "Prawa autorskie",
  shortNote:
    "Strona HyperView została przygotowana i opracowana w oparciu o wcześniej sporządzony projekt graficzny. \nW założeniu projektowym, jak i na samej stronie zostały wykorzystane ikony, grafiki oraz obrazy wykonane przez zewnętrznych autorów",
  designer: {
    note: "Główny graficzny projekt strony został przygotowany przez",
    name: "Justynę Piekarz",
    linkSettings: {
      href: designerPortfolio,
      target: "_blank",
      rel: "noreferrer noopener",
      title: "Justyna Piekarz - portfolio",
    },
  },
  picturesInfo: {
    note: "Na stronie oprócz własnych ilustracji, są wykorzystywane grafiki różnych autorów, którzy udostępniają swoje prace bez konieczności ich przypisania. Wszystkie obrazy można znaleźć na portalu",
    name: "Pixabay",
    linkSettings: {
      href: copyrights.pixabay,
      target: "_blank",
      rel: "noreferrer noopener",
      title: "Pixabay",
    },
  },
  iconInfo: {
    note: "Dodatkowo, oprócz ikon własnego autorstwa używamy poszczególnych piktogramów:",
    sharedLinkSettings: {
      target: "_blank",
      rel: "noreferrer noopener",
    },
    iconList: [
      {
        name: "Floor Plan Icon z Icons8",
        link: copyrights.floorPlanIcon,
      },
      {
        name: "Map Marker z Icons8",
        link: copyrights.mapMarker,
      },
      {
        name: "Real Estate z Icons8",
        link: copyrights.realEstate,
      },
      {
        name: "City Icon z Icons8",
        link: copyrights.cityIcon,
      },
      {
        name: "Multicast Icon z Icons8",
        link: copyrights.multicastIcon,
      },
      {
        name: "Coins Icon in iOS Style z Icons8",
        link: copyrights.coinsIcon,
      },
      {
        name: "Environment Care Icon in iOS Style z Icons8",
        link: copyrights.environmentCareIcon,
      },
      {
        name: "Safety Hat Icon in iOS Style z Icons8",
        link: copyrights.safetyHatIcon,
      },
      {
        name: "Ruins Icon in iOS Style z Icons8",
        link: copyrights.ruinsIcon,
      },
      {
        name: "Earthquakes Icon in iOS Style z Icons8",
        link: copyrights.earthquakesIcon,
      },
      {
        name: "Webhook Icon in iOS Style z Icons8",
        link: copyrights.webhookIcon,
      },
      {
        name: "Time, clock icon z Iconfinder",
        link: copyrights.timeIcon,
      },
      {
        name: "Phone icon z pngimg",
        link: copyrights.phoneIcon,
      },
      {
        name: "Location Pin z Flaticon",
        link: copyrights.locationPin,
      },
      {
        name: "Envelope z Capstone Church",
        link: copyrights.envelopIcon,
      },
      {
        name: "Compas z Vecteezy",
        link: copyrights.compasIcon,
      },
      {
        name: "Linkedin, Twitter, Facebook icons from React Icons",
        link: copyrights.reactIcons,
      },
      {
        name: "document write pencil z toppng",
        link: copyrights.pencilDrawingIcon,
      },
      {
        name: "city silhouette png png z toppng",
        link: copyrights.citySilhouette,
      },
      {
        name: "logo miasta Gliwice z gliwice.eu",
        link: copyrights.gliwiceIcon,
      },
      {
        name: "herb miasta Krosno z Wikipedii",
        link: copyrights.herbKrosno,
      },
      {
        name: "herb miasta Piekary Śląskie z Wikipedii",
        link: copyrights.herbPiekary,
      },
      {
        name: "herb miasta Bełchatów z Wikipedii",
        link: copyrights.herbBelchatow,
      },
      {
        name: "herb miasta Bytom z Wikipedii",
        link: copyrights.herbBytom,
      },
      {
        name: "herb miasta Gliwice z Wikipedii",
        link: copyrights.herbGliwice,
      },
      {
        name: "herb miasta Jastrzębie Zdrój z Wikipedii",
        link: copyrights.herbJastrzebie,
      },
      {
        name: "herb miasta Jaworzno z Wikipedii",
        link: copyrights.herbJaworzno,
      },
      {
        name: "herb miasta Kielce z Wikipedii",
        link: copyrights.herbKielce,
      },
      {
        name: "herb miasta Kraków z Wikipedii",
        link: copyrights.herbKrakow,
      },
      {
        name: "herb miasta Mysłowice z Wikipedii",
        link: copyrights.herbMyslowice,
      },
      {
        name: "herb powiatu olesnickiego z Wikipedii",
        link: copyrights.herbOlesnice,
      },
      {
        name: "herb miasta Sosnowiec z Wikipedii",
        link: copyrights.herbSosnowiec,
      },
      {
        name: "herb miasta Szczecin z Wikipedii",
        link: copyrights.herbSzczecin,
      },
      {
        name: "herb miasta Tarnów z Wikipedii",
        link: copyrights.herbTarnów,
      },
      {
        name: "herb miasta Tychy z Wikipedii",
        link: copyrights.herbTychy,
      },
    ],
  },
};

export { pageContent };
