import React from "react";

const CopyrightsNoteWithLink = ({ content }) => (
  <p className="copyrights__note">
    {content.note}{" "}
    <a className="copyrights__link" {...content.linkSettings}>
      {content.name}
    </a>
    .
  </p>
);

export default CopyrightsNoteWithLink;
