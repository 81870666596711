import React from "react";
import CopyrightsNoteWithLink from "./CopyrightsNoteWithLink";
import CopyrightsNoteWithList from "./CopyrightsNoteWithList";
import { pageContent } from "staticContent/copyrights";
import "styles/components/copyrights.scss";

const { pageTitle, shortNote, designer, picturesInfo, iconInfo } = pageContent;

const Copyrights = () => (
  <>
    <section className="section hero copyrights">
      <h1 className="hero__banner-text">{pageTitle}</h1>
    </section>
    <section className="section">
      <p className="copyrights__note copyrights__note--main">{shortNote}</p>
      <CopyrightsNoteWithLink content={designer} />
      <CopyrightsNoteWithLink content={picturesInfo} />
      <CopyrightsNoteWithList content={iconInfo} />
    </section>
  </>
);

export default Copyrights;
